<!--
  Main View Page
-->
<template>
  <router-view />
</template>

<script>
export default {
  name: 'Main',
};
</script>

<style scoped></style>
